<template>
    <svg class="too-big" width="1321" viewBox="0 0 1321 411" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M399.92 0.809998V406.01H309.62V209.62L200.14 319.1L90.6501 209.62V406.01H0.350098V0.809998L200.13 201.15L399.92 0.809998Z" fill="black"/>
        <path d="M555.74 162.22H679.9V249.13H555.74V316.85H713.76V406.02H465.44V5.32999H713.75V94.5H555.74V162.22Z" fill="black"/>
        <path d="M776.42 5.32999H867.27V316.29H1022.47V406.02H776.42V5.32999Z" fill="black"/>
        <path d="M1192.97 410.53C1267.46 410.53 1320.51 363.69 1320.51 291.45C1320.51 236.15 1288.34 196.08 1233.04 171.25L1211.6 161.65C1174.92 145.29 1157.42 134 1157.42 113.12C1157.42 95.06 1173.22 85.46 1188.46 85.46C1206.54 85.46 1218.83 90.41 1238.11 113.39L1300.54 50.78C1266.76 11.49 1232.94 0.799988 1188.46 0.799988C1124.69 0.799988 1066.56 46.52 1066.56 116.49C1066.56 170.67 1099.86 215.26 1160.23 242.34L1182.8 252.5C1216.67 267.74 1229.65 279.02 1229.65 297.09C1229.65 315.71 1217.8 325.87 1191.27 325.87C1163.79 325.87 1135.21 304.12 1117.69 276.7L1053.99 340.59C1082.63 386.89 1132.26 410.53 1192.97 410.53Z" fill="black"/>
    </svg>
</template>

<script>
export default {
    name: 'Mels',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
