<template>
    <div
        id="home"
        class="page-wrapper page-home"
        data-gsap-change-color="default"
        data-gsap-end-footer
    >
        <!-- Hero Text -->
        <header
            class="hero-text site-max-width"
            hero-transition-group
            data-gsap-change-color="default"
        >
            <div class="container" data-inview="fadeInUp" data-delay="100">
                <p class="big-text">
                    Resonating with local culture, and your customers, is our specialty. With our support at every turn, we will make your brand fluent in Québécois!
                </p>

                <div class="float-img-wrap">
                    <div class="float-img"></div>
                </div>
            </div>
        </header>

        <!-- Video -->
        <div class="full-video" data-gsap-change-color="default" hero-transition-group>
            <div class="container">
                <div data-inview="revealRight" data-color="colorAccentLight" data-delay="300">
                    <img
                        class="main"
                        src="@/assets/imgs/qolab-en.jpg"
                        alt="Montagnes russes"
                    />
                </div>
                <div class="content">
                    <a href="https://vimeo.com/962347975" class="glightbox" >
                        <h2 class="video-title big-text" data-inview="fadeInUp" data-delay="100">

                            See what we're <br /> capable of.

                            <svg
                                class="play-icon"
                                xmlns="http://www.w3.org/2000/svg"
                                width="75.347"
                                height="75.348"
                                viewBox="0 0.026 75.347 75.348"
                                data-inview="fadeInUp" data-delay="200"
                            >
                                <g>
                                    <path
                                        d="M30.733 56.192V18.54c0-.983.591-1.868 1.499-2.244a2.427 2.427 0 0 1 .788-.18c.047-.003.094-.005.14-.005a2.424 2.424 0 0 1 1.718.712L53.704 35.65a2.416 2.416 0 0 1 .664 1.239 2.425 2.425 0 0 1-.664 2.195L34.878 57.91a2.428 2.428 0 0 1-1.718.712 2.414 2.414 0 0 1-.163-.006 2.423 2.423 0 0 1-.765-.18 2.428 2.428 0 0 1-1.5-2.243Zm4.857-31.789v25.925l12.962-12.962L35.59 24.403Z"
                                        fill="#ceffb4"
                                        fill-rule="evenodd"
                                        data-name="Path 14"
                                    />
                                    <path
                                        d="M37.674-.474c20.85 0 37.673 16.824 37.673 37.674a37.553 37.553 0 0 1-37.673 37.674A37.483 37.483 0 0 1 .004 37.813 39.296 39.296 0 0 1 0 37.2 37.554 37.554 0 0 1 37.674-.474Zm0 70.496c18.063 0 32.72-14.655 32.72-32.822 0-18.063-14.657-32.72-32.72-32.72C19.51 4.48 4.851 19.137 4.851 37.2a32.773 32.773 0 0 0 32.823 32.822Z"
                                        fill="#ceffb4"
                                        fill-rule="evenodd"
                                        data-name="Path 13"
                                    />
                                </g>
                            </svg>
                        </h2>
                    </a>
                </div>
            </div>
        </div>

        <!-- Content -->
        <div id="zone-bureau" class="content-text site-max-width" data-gsap-change-color="black">
            <div class="container">
                <article>
                    <div class="cols">
                        <div class="left" data-inview="fadeInUp" data-delay="100">
                            <h3 class="small-title">The Creative Office</h3>
                        </div>
                        <div class="right" data-inview="fadeInUp" data-delay="200">
                            <p class="small-text right-text">
                                Saying it right and spreading the word.
                                <br /><br />
                                With a legacy in telecommunications, entertainment, media, and culture, Quebecor sets the standard. From this foundation emerges Qolab—a full-service team specializing in the French market—propelled by Quebecor's unmatched resources and expertise. Qolab offers brands an unparalleled entry point into Quebec, standing ready to fulfill all your strategic, creative, and content production needs.
                                <br /><br />
                                It's more than a 360 media ecosystem; our deep-rooted connections and savoir-faire provide insights tailored to the Quebec audience, ensuring your message hits home every time.
                            </p>
                        </div>
                    </div>
                </article>
            </div>
        </div>

        <!-- Content -->
        <div
            id="zone-ecosysteme"
            class="content-text graph-wrap no-border site-max-width"
            data-gsap-change-color="black"
            data-gsap-trigger-graph-init
        >
            <div class="container" data-gsap-trigger-graph>
                <div class="cols">
                    <div class="left">
                        <p class="small-text float-text" data-inview="fadeInUp" data-delay="100">
                            What do you get when you unite Quebec’s top broadcasting channels with leading specialists in production, advertising, and digital media? A cohesive network of top-tier partners, each contributing expertise and insight to enhance the collective.
                        </p>
                    </div>
                    <div class="right">
                        <!-- Graph -->
                        <div class="graph">
                            <img
                                class="placeholder"
                                src="@/assets/imgs/graph-placeholder.png"
                                alt=""
                            />

                            <div class="circles">
                                <div class="circle center focus">
                                    <Logo class="logo" />
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-plus"
                                        width="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </div>

                                <div :class="{ 'is-active': circle === 1 }" class="circle top-left" data-gsap-trigger-graph-0 @click.prevent="togglePopup(true, 1)">
                                    <Mels class="logo" /><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-plus"
                                        width="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </div>
                                <div :class="{ 'is-active': circle === 2 }" class="circle top-right" data-gsap-trigger-graph-0 @click.prevent="togglePopup(true, 2)">
                                    <Quebecor class="logo down" /><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-plus"
                                        width="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </div>
                                <div :class="{ 'is-active': circle === 3 }" class="circle is-tva bottom-right" data-gsap-trigger-graph-0 @click.prevent="togglePopup(true, 3)">
                                    <Tva class="logo" /><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-plus"
                                        width="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </div>
                                <div :class="{ 'is-active': circle === 4 }" class="circle bottom-left" data-gsap-trigger-graph-0 @click.prevent="togglePopup(true, 4)">
                                    <Elmire class="logo" /><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="icon icon-plus"
                                        width="24"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <line x1="12" y1="5" x2="12" y2="19" />
                                        <line x1="5" y1="12" x2="19" y2="12" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mobile-row">
                    <div class="top" @click.prevent="togglePopup(true, 1)">
                        <Mels class="logo" />
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-plus"
                            width="24"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="12" y1="5" x2="12" y2="19" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                        </svg>
                    </div>
                    <!-- <div class="bottom">
                        <p class="small-text text">
                            Agence de marketing numérique clés en main, Elmire a pour but de faire la promotion de marques, de produits ou de services par différents canaux et plateformes numériques. Notre partenariat avec Elmire nous permet de mettre à profit leur expertise Web pour aider nos clients souhaitant se démarquer sur la toile. Ils savent nous conseiller pour des programmations Web des plus optimales selon les meilleures pratiques en expérience utilisateur du milieu.
                        </p>
                    </div> -->
                </div>
                <div class="mobile-row">
                    <div class="top" @click.prevent="togglePopup(true, 2)">
                        <Quebecor class="logo adjust down" />
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-plus"
                            width="24"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="12" y1="5" x2="12" y2="19" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                        </svg>
                    </div>
                    <!-- <div class="bottom">
                        <p class="small-text text">
                            Gestev est une firme spécialisée dans l’organisation et la gestion d’événements de toute envergure. C’est grâce à eux que nos idées plus expérientielles voient le jour. Leur expertise en activation terrain, en activation de commandite et en marketing événementiel nous sert chaque fois qu’on désire sortir du cadre. Ils nous offrent l’ensemble de leurs services de création, d’organisation, de mise en marché et de promotion.
                        </p>
                    </div> -->
                </div>
                <div class="mobile-row is-tva">
                    <div class="top" @click.prevent="togglePopup(true, 3)">
                        <Tva class="logo" />
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-plus"
                            width="24"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="12" y1="5" x2="12" y2="19" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                        </svg>
                    </div>
                    <!-- <div class="bottom">
                        <p class="small-text text">
                            Reconnue mondialement pour ses studios de tournage et sa main-d’œuvre hautement qualifiée, MELS est l’une des plus importantes entreprises de services de cinéma et de télévision au Canada. Notre étroit partenariat avec MELS nous permet de réaliser nos plus grandes idées. Bien que notre équipe de production interne n’ait rien à envier à personne, on a souvent recours au soutien et à l’expertise de MELS, qui nous accompagne dans de nombreux projets.
                        </p>
                    </div> -->
                </div>
                <div class="mobile-row">
                    <div class="top" @click.prevent="togglePopup(true, 4)">
                        <Elmire class="logo" />
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-plus"
                            width="24"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <line x1="12" y1="5" x2="12" y2="19" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                        </svg>
                    </div>
                    <!-- <div class="bottom">
                        <p class="small-text text">
                            Elmire: Your digital marketing experts. Specializing in search engine optimization, online marketing, and website design, our mission is to skyrocket your online visibility.
                        </p>
                    </div>-->
                </div>
            </div>
        </div>

        <!-- Content -->
        <div
            id="zone-valeurs"
            class="content-text content-valeurs is-red no-border site-max-width"
            data-gsap-change-color="colorAccent1"
        >
            <div class="container">
                <div class="top" data-gsap-change-color="colorAccent1">
                    <h3 class="small-title">Services</h3>
                </div>
                <div class="carousel carousel-valeurs" data-gsap-change-color="colorAccent1">
                    <div class="carousel-cell">
                        <div class="cols">
                            <div class="left">
                                <h4 class="big-title">Advertising</h4>
                            </div>
                            <div class="right">
                                <p class="small-text text">
                                    Strategy is key!. Think of us as creative catalysts: we turn business objectives into captivating narratives.  Together, let’s make the most of your audience’s attention by crafting impactful and captivating ads.
                                    <br>
                                    &middot;&nbsp;Digital<br>
                                    &middot;&nbsp;Traditional<br>
                                    &middot;&nbsp;Print

                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-cell">
                        <div class="cols">
                            <div class="left">
                                <h4 class="big-title">Content</h4>
                            </div>
                            <div class="right">
                                <p class="small-text text">
                                    We thrive on staying ahead of the curve, but we take it one step further by shaping the curve itself. That means keeping up with platform evolution and innovating at the edge of new digital frontiers. From podcasts to magazine articles, to thoughtfully crafted social ads—we do it all.
                                    <br>
                                    &middot;&nbsp;Social media<br>
                                    &middot;&nbsp;Audio content<br>
                                    &middot;&nbsp;Video<br>
                                    &middot;&nbsp;Print and web<br>
                                    &middot;&nbsp;Influencers
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-cell">
                        <div class="cols">
                            <div class="left">
                                <h4 class="big-title">Production</h4>
                            </div>
                            <div class="right">
                                <p class="small-text text">
                                    Qolab is your one-stop production partner.
                                    <br>
                                    From smartphone storytelling to grand-scale productions, we craft tailored approaches to meet your every need.
                                    <br>
                                    &middot;&nbsp;Video<br>
                                    &middot;&nbsp;Digital<br>
                                    &middot;&nbsp;Print<br>
                                    &middot;&nbsp;TV<br>
                                    &middot;&nbsp;Radio
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-cell">
                        <div class="cols">
                            <div class="left">
                                <h4 class="big-title">Brand Identity</h4>
                            </div>
                            <div class="right">
                                <p class="small-text text">
                                    Your brand identity isn't just a first impression—it's every impression.
                                    <br><br>
                                    That's why we craft brand identities that not only stand out but also resonate deeply with your audience. With a strong, consistent, and unforgettable brand identity, you'll build trust, foster loyalty, and leave a lasting impression that keeps customers coming back for more.
                                    <br>
                                    &middot;&nbsp;Brand positioning <br>
                                    &middot;&nbsp;Brand image
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-cell">
                        <div class="cols">
                            <div class="left">
                                <h4 class="big-title">Training</h4>
                            </div>
                            <div class="right">
                                <p class="small-text text">
                                    We don’t gatekeep our expertise. In fact, we’re eager to help elevate your team’s knowledge and productivity. Reach out to us for more info on our custom training programs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Content -->
        <div id="logos" class="logos site-max-width is-en" data-gsap-change-color="default">
            <div class="container">
                <div class="top">
                    <h3 class="small-title" data-inview="fadeInUp" data-delay="100">
                        Our clients
                    </h3>
                </div>
                <div class="carousel carousel-logos" data-inview="fadeInUp" data-delay="200">
                    <div class="carousel-cell" v-for="(items, i) in logos" :key="i">
                        <div class="logos-grid">
                            <a
                                href="#"
                                @click.prevent.stop
                                class="logo-item"
                                target="_blank"
                                rel="noopener noreferrer"
                                v-for="(item, i) in items"
                                :key="i"
                                :class="{
                                    'adjust': item.includes('Fizz') || item.includes('CAA') || item.includes('RQRA') || item.includes('flg'),
                                    'adjust2':item.includes('Tourisme') || item.includes('Hubert'),
                                    'adjust3':item.includes('Consommateurs'),
                                }"
                            >
                                <img :src="'/static/clients/' + item" :alt="item.replace('.svg', '')" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Popup informations -->
        <div id="popup-informations" class="popup-informations" :class="{ 'is-active': popupIsActive }"  @click.prevent="togglePopup(false)">
            <div class="container"  @click.prevent.stop>
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-close" width="32" viewBox="0 0 24 24" stroke-width="1" fill="none" stroke-linecap="round" stroke-linejoin="round" @click.prevent="togglePopup(false)">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
                <div class="cols" v-if="circle === 4">
                    <div class="left">
                        <Elmire class="logo" />
                    </div>
                    <div class="right">
                        <p class="small-text text">
                            Elmire: Your digital marketing experts. Specializing in search engine optimization, online marketing, and website design, our mission is to skyrocket your online visibility.
                        </p>
                    </div>
                </div>
                <div class="cols" v-if="circle === 1">
                    <div class="left">
                        <Mels class="logo" />
                    </div>
                    <div class="right">
                        <p class="small-text text">
                            Known worldwide for its cutting-edge film studios and top-tier talent, MELS stands tall as a powerhouse in Canada's film and television services industry. From sound and image postproduction to distribution and broadcasting, MELS offers a comprehensive suite of cinema-related services.
                        </p>
                    </div>
                </div>
                <div class="cols is-tva" v-if="circle === 3">
                    <div class="left">
                        <Tva class="logo" />
                    </div>
                    <div class="right">
                        <p class="small-text text">

                        </p>
                    </div>
                </div>
                <div class="cols" v-if="circle === 2">
                    <div class="left">
                        <Quebecor class="logo" />
                    </div>
                    <div class="right">
                        <p class="small-text text">
                            Quebecor stands out as one of Quebec's foremost broadcasters, delivering a diverse range of entertainment, news, and public affairs programming across digital, TV, out-of-home, newspapers, magazines, and audio platforms. As a leading media distributor, Quebecor commands a significant presence in the Quebec landscape. Our platforms serve as a strategic ally for brands seeking to connect with Quebec audiences.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Footer Nav -->
        <Footer />
    </div>
</template>

<script>
// @ is an alias to /src
import Logo from '@/components/svgs/Logo.vue'
import Elmire from '@/components/svgs/Elmire.vue'
import Quebecor from '@/components/svgs/Quebecor.vue'
import Tva from '@/components/svgs/Tva.vue'
// import Leliken from '@/components/svgs/Leliken.vue'
import Mels from '@/components/svgs/Mels.vue'
// import Gestev from '@/components/svgs/Gestev.vue'
import Footer from '@/components/Footer.vue'
import { mapState, mapGetters } from 'vuex'

import GLightbox from 'glightbox'
const Flickity = require('flickity')
require('flickity-fade')

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

import { initInViewAnimations } from '@/plugins/InViewAnimations'

export default {
    name: 'Home',

    components: {
        Logo,
        Footer,
        Elmire,
        Quebecor,
        Tva,
        // Leliken,
        Mels,
        // Gestev,
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        GLightbox()
                        initInViewAnimations()
                    }, 1)

                    setTimeout(() => {
                        this.carouselValeurs = new Flickity(
                            document.querySelector('.carousel-valeurs'),
                            {
                                cellAlign: 'center',
                                wrapAround: true,
                                lazyLoad: 1,
                                imagesLoaded: true,
                                prevNextButtons: true,
                                pageDots: true,
                                fade: true,
                            }
                        )
                        this.carouselLogos = new Flickity(document.querySelector('.carousel-logos'), {
                            cellAlign: 'center',
                            wrapAround: true,
                            lazyLoad: 1,
                            imagesLoaded: true,
                            prevNextButtons: true,
                            pageDots: true,
                        })

                        setTimeout(() => {
                            this.initGsapHero()
                            this.initGsapBgColors()
                            this.initGsapGraph()

                            this.gsapActivateFooter()

                            this.carouselValeurs.resize()
                            this.carouselLogos.resize()

                            setTimeout(() => {
                                ScrollTrigger.refresh()
                            }, 475)
                        }, 1000)
                    }, 1000)
                }
            },
            immediate: true,
        },
    },

    data() {
        return {
            carouselValeurs: null,
            carouselLogos: null,
            popupIsActive: false,
            circle: 'none',
            teamMembers: [
                {
                    image: '/static/team/sebastien.jpg',
                    name: 'Sébastien Viau',
                    desc: 'Directeur général',
                    speed: 100,
                },
                {
                    image: '/static/team/rejean.jpg',
                    name: 'Réjean Fortin',
                    desc: 'Directeur principal stratégie de marque',
                    speed: 200,
                },
                {
                    image: '/static/team/jocelyn.jpg',
                    name: 'Jocelyn Goyer',
                    desc: 'Directeur principal création',
                    speed: 400,
                },
                {
                    image: '/static/team/lucie.jpg',
                    name: 'Lucie Gauthier',
                    desc: 'Directrice principale service production commerciale',
                    speed: 400,
                },
                {
                    image: '/static/team/mylene.jpg',
                    name: 'Mylène Loiselle',
                    desc: 'Directrice principale stratégie de marque et de contenu',
                    speed: 100,
                },
                // {
                //     image: '/static/team/diane.jpg',
                //     name: 'Diane Gignac',
                //     desc: 'Directrice de production imprimée et numérique',
                //     speed: 200,
                // },
                {
                    image: '/static/team/audre-anne.jpg',
                    name: 'Andrée-Anne Gauthier',
                    desc: 'Directrice stratégie omnicanale',
                    speed: 300,
                },
                {
                    image: '/static/team/hugues.jpg',
                    name: 'Hugues Choquette',
                    desc: 'Directeur de création',
                    speed: 300,
                },
                {
                    image: '/static/team/chantal.jpg',
                    name: 'Chantal Joly',
                    desc: 'Directrice de création',
                    speed: 100,
                },
                {
                    image: '/static/team/meggie.jpg',
                    name: 'Meggie Lavoie',
                    desc: 'Directrice service-conseil',
                    speed: 200,
                },
                {
                    image: '/static/team/corinne.jpg',
                    name: 'Corinne Sevigny',
                    desc: 'Directrice service-conseil',
                    speed: 300,
                },
                {
                    image: '/static/team/noemie-t.jpg',
                    name: 'Noémie Tremblay',
                    desc: 'Directrice service-conseil',
                    speed: 400,
                },
                // {
                //     image: '/static/team/veronic.jpg',
                //     name: 'Véronic Cholette-Roberts',
                //     desc: 'Directrice média, Intercos',
                //     speed: 100,
                // },
                {
                    image: '/static/team/pascale.jpg',
                    name: 'Pascale Fregeau',
                    desc: 'Directrice service-conseil',
                    speed: 200,
                },
                {
                    image: '/static/team/laeticia.jpg',
                    name: 'Laetitia Metayer',
                    desc: 'Directrice, planification stratégique',
                    speed: 300,
                },
                {
                    image: '/static/team/juliette.jpg',
                    name: 'Juliette Ruer',
                    desc: 'Éditrice en chef',
                    speed: 400,
                },
                {
                    image: '/static/team/nour.jpg',
                    name: 'Nour Haik',
                    desc: 'Directrice de production',
                    speed: 200,
                },
                {
                    image: '/static/team/noemie-q.jpg',
                    name: 'Noemie Quilleré',
                    desc: 'Superviseure, stratégie et création de contenu',
                    speed: 200,
                },
                {
                    image: '/static/team/morgane.jpg',
                    name: 'Morgane Bourel',
                    desc: 'Superviseure, stratégie et création de contenu',
                    speed: 200,
                },
                {
                    image: '/static/team/audrey.jpg',
                    name: 'Audrey Desmeules',
                    desc: 'Productrice exécutive',
                    speed: 200,
                },
                {
                    image: '/static/team/ouiam.jpg',
                    name: 'Ouiam Didane',
                    desc: 'Superviseure, Contenus, Vidéotron-Fizz',
                    speed: 200,
                },
                {
                    image: '/static/team/genevieve.jpg',
                    name: 'Geneviève Cayer',
                    desc: 'Productrice exécutive',
                    speed: 200,
                },
            ],
            logos: [
                [
                    'CAA_Quebec_RGB_W_Fr.svg',
                    'desjardins.png',
                    'toyota.png',
                    'Metro_RGB_W.svg',
                    'flg.png',
                    'PnG_RGB_W.svg',
                    'St-Hubert_RGB_W.svg',
                    'bmr.png',
                    'Universite_de_Montreal_RGB_W.svg',
                    'Air_Inuit_RGB_W.svg',
                ],
                [
                    'Banque_Laurentienne_RGB_W_Fr.svg',
                    'Tourisme_Autochtone_Quebec_RGB_W_Fr.svg',
                    'nespresso.png',
                    'Option_Consommateurs_RGB_W.svg',
                    'Videotron_RGB_W_Fr.svg',
                    'Fizz_RGB_W.svg',
                    'Quebecor_RGB_W_Fr.svg',
                    'TVA_RGB_W.svg',
                    'mels.png'
                ]
            ]
        }
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        togglePopup(value, selectedCircle = 'none') {
            if (!value) {
                setTimeout(() => {
                    this.circle = 'none'
                }, 475)
            }
            if (selectedCircle !== 'none') {
                this.circle = selectedCircle
            }
            this.popupIsActive = value
        },
        initGsapHero() {
            const imgWords = gsap.utils.toArray('.img-word')
            const floatImgWrap = document.querySelector('.float-img-wrap')
            const floatImg = document.querySelector('.float-img')

            imgWords.forEach(link => {
                link.addEventListener('mouseenter', e => {
                    const targetImage = e.target.dataset.img
                    const t1 = gsap.timeline()
                    t1.set(floatImg, {
                        backgroundImage: `url(${targetImage})`,
                    }).to(floatImgWrap, {
                        duration: 0.5,
                        autoAlpha: 1,
                    })
                })
                link.addEventListener('mouseleave', () => {
                    const tl = gsap.timeline()
                    tl.to(floatImgWrap, {
                        duration: 0.5,
                        autoAlpha: 0,
                    })
                })
                link.addEventListener('mousemove', e => {
                    let xpos = e.clientX
                    let ypos = e.clientY
                    const tl = gsap.timeline()
                    tl.to(floatImgWrap, {
                        x: xpos,
                        y: ypos,
                    })
                })
            })
        },
        initGsapBgColors() {
            const classes = [
                'default',
                'dark',
                'black',
                'light',
                'colorAccent1',
                'colorAccent2',
                'colorAccent3',
                'colorAccent4',
                'colorAccent5',
            ]
            gsap.utils.toArray('[data-gsap-change-color]').forEach(block => {
                ScrollTrigger.create({
                    trigger: block,
                    scrub: 1.475,
                    start: 'top 75%',
                    end: 'bottom 30%',
                    onEnter: () => {
                        if (!document.querySelector('.page-wrapper.page-home')) {
                            return;
                        }
                        // console.log(block, block.dataset.gsapChangeColor)
                        classes.forEach(className => {
                            document.querySelector('.page-wrapper.page-home').classList.remove(className)
                        })
                        document.querySelector('.page-wrapper.page-home').classList.add(block.dataset.gsapChangeColor)
                    },
                    onEnterBack: () => {
                        if (!document.querySelector('.page-wrapper.page-home')) {
                            return;
                        }
                        // console.log(block, block.dataset.gsapChangeColor)
                        classes.forEach(className => {
                            document.querySelector('.page-wrapper.page-home').classList.remove(className)
                        })
                        document.querySelector('.page-wrapper.page-home').classList.add(block.dataset.gsapChangeColor)
                    },
                })
            })
        },
        initGsapGraph() {
            const graph = document.querySelector('[data-gsap-trigger-graph-init]')
            ScrollTrigger.create({
                trigger: graph,
                once: true,
                start: 'top 30%',
                onEnter: () => {
                    const graphs = gsap.utils.toArray('[data-gsap-trigger-graph-0]')
                    graphs.forEach(graph => {
                        const tl = gsap.timeline()
                        tl.to(graph, {
                            marginTop: 0,
                            marginBottom: 0,
                            marginLeft: 0,
                            marginRight: 0,
                            opacity: 1,
                            delay: 0,
                            ease: 'power1.out'
                        })
                    })
                },
            })

            const reveals = gsap.utils.toArray('[data-gsap-reveal]')
            reveals.forEach(reveal => {
                ScrollTrigger.create({
                    trigger: reveal,
                    once: true,
                    start: 'top 70%',
                    onEnter: () => {
                        if (!document.querySelector('[data-gsap-reveal]')) {
                            return
                        }
                        const tl = gsap.timeline()
                        tl.to(reveal, {
                            y: '0%',
                            x: '0%',
                            opacity: 1,
                            delay: 0,
                            ease: 'power1.out'
                        })

                        const tl2 = gsap.timeline()
                        tl2.to(reveal.querySelector('.content'), {
                            y: '0%',
                            x: '0%',
                            opacity: 1,
                            delay: 0.75,
                            ease: 'power1.out'
                        })

                        const tl3 = gsap.timeline()
                        tl3.to(document.querySelector('.departement-item.item-center .content'), {
                            y: '0%',
                            x: '0%',
                            opacity: 1,
                            delay: 0.35,
                            ease: 'power1.out'
                        })

                        const tl4 = gsap.timeline()
                        tl4.to(document.querySelector('.departement-item.item-left .title'), {
                            opacity: 1,
                            delay: 0.75,
                            ease: 'power1.out'
                        })
                        const tl5 = gsap.timeline()
                        tl5.to(document.querySelector('.departement-item.item-center .title'), {
                            opacity: 1,
                            delay: 0.75,
                            ease: 'power1.out'
                        })
                        const tl6 = gsap.timeline()
                        tl6.to(document.querySelector('.departement-item.item-right .title'), {
                            opacity: 1,
                            delay: 0.75,
                            ease: 'power1.out'
                        })

                        const tl7 = gsap.timeline()
                        tl7.to(document.querySelector('.departement-item.item-left .regular-title'), {
                            y: 0,
                            x: 0,
                            delay: 0.75,
                            ease: 'power1.out'
                        })
                        const tl8 = gsap.timeline()
                        tl8.to(document.querySelector('.departement-item.item-center .regular-title'), {
                            y: 0,
                            x: 0,
                            delay: 0.75,
                            ease: 'power1.out'
                        })
                        const tl9 = gsap.timeline()
                        tl9.to(document.querySelector('.departement-item.item-right .regular-title'), {
                            y: 0,
                            x: 0,
                            delay: 0.75,
                            ease: 'power1.out'
                        })
                    },
                })
            })
        },
        gsapActivateFooter() {
            const footerNav = document.querySelector('[data-gsap-activate-footer]')
            const footerFold = document.querySelector('.footer-fold')

            ScrollTrigger.create({
                trigger: footerNav,
                start: 'bottom bottom',
                onEnter: () => {
                    if (!document.querySelector('.page-wrapper.page-home')) {
                        return;
                    }
                    footerFold.classList.add('is-active')
                },
                onLeaveBack: () => {
                    if (!document.querySelector('.page-wrapper.page-home')) {
                        return;
                    }
                    footerFold.classList.remove('is-active')
                },
            })
        },
        gsapScrollTo(selector) {
            gsap.to(window, {duration: 1, scrollTo: selector})
        }
    },
}
</script>
