<template>
    <svg width="703" height="232" viewBox="0 0 703 232" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M169.61 19.9H227.48V32.47H186.38V45.5H222.55V58.06H186.38V71.55H228.03V84.12H169.61V19.9V19.9Z" fill="white"/>
        <path d="M199.73 0.609985L213.78 5.75998L201.89 15.19H188.69L199.73 0.609985Z" fill="white"/>
        <path d="M518.83 52.18V52C518.83 41.05 509.23 31.93 495.71 31.93C482.18 31.93 472.81 40.87 472.81 51.82V52C472.81 62.95 482.4 72.08 495.92 72.08C509.45 72.07 518.83 63.13 518.83 52.18ZM455.25 52.18V52C455.25 33.84 472.37 18.97 495.92 18.97C519.48 18.97 536.39 33.66 536.39 51.82V52C536.39 70.16 519.27 85.03 495.71 85.03C472.14 85.03 455.25 70.34 455.25 52.18Z" fill="white"/>
        <path d="M580.77 51.0799C588.95 51.0799 593.64 47.4399 593.64 42.0499V41.8599C593.64 35.8399 588.62 32.7399 580.45 32.7399H563.77V51.0799H580.77ZM546.97 20.0599H581.86C591.57 20.0599 599.08 22.3399 604.11 26.5399C608.36 30.0899 610.65 35.1099 610.65 41.1299V41.3099C610.65 51.6199 603.99 58.1099 594.29 61.1199L612.93 83.9399H593.31L576.95 63.4999H563.76V83.9399H546.97V20.0599V20.0599Z" fill="white"/>
        <path d="M50.94 70.34L38.5 61.49L49.19 51.45L61.73 60.94C63.14 58.29 63.8 55.37 63.8 52.17V52C63.8 41.05 54.22 31.93 40.68 31.93C27.17 31.93 17.79 40.87 17.79 51.82V52C17.79 62.95 27.38 72.08 40.91 72.08C44.61 72.07 47.99 71.43 50.94 70.34ZM63.37 79.56C56.94 83.03 49.2 85.03 40.68 85.03C17.13 85.03 0.219971 70.34 0.219971 52.17V52C0.219971 33.84 17.35 18.97 40.91 18.97C64.46 18.97 81.37 33.66 81.37 51.82V52C81.37 58.66 78.97 65.05 74.61 70.35L82.47 75.9L71.79 85.85L63.37 79.56Z" fill="white"/>
        <path d="M90.74 56.5599V20.0599H107.53V56.1899C107.53 66.5999 113.75 71.9799 123.99 71.9799C134.24 71.9799 140.46 66.7699 140.46 56.6599V20.0599H157.26V56.1099C157.26 75.4599 144.28 84.95 123.78 84.95C103.29 84.94 90.74 75.3599 90.74 56.5599Z" fill="white"/>
        <path d="M277.13 71.6199C284.56 71.6199 289.03 69.4199 289.03 64.6799V64.4799C289.03 60.1999 285.21 57.5599 276.6 57.5599H257.17V71.6099H277.13V71.6199ZM272.67 45.8899C280.09 45.8899 284.77 43.8799 284.77 39.1299V38.9499C284.77 34.7599 280.84 32.3799 273.75 32.3799H257.17V45.8899H272.67ZM240.83 20.0599H276.27C284.99 20.0599 291.86 22.0599 296.22 25.7199C299.71 28.6399 301.46 32.1899 301.46 36.5699V36.7699C301.46 43.9699 296.88 47.9799 291.42 50.5299C300.26 53.3699 305.7 57.65 305.7 66.2299V66.4099C305.7 78.0999 294.36 83.9399 277.12 83.9399H240.82V20.0599H240.83Z" fill="white"/>
        <path d="M315.97 20.0599H373.55V32.5499H332.66V45.5199H368.65V58.0099H332.66V71.4299H374.1V83.9399H315.97V20.0599Z" fill="white"/>
        <path d="M381.29 52.18V52C381.29 33.84 397.65 18.97 421.11 18.97C435.5 18.97 444.11 22.99 451.21 28.82L440.52 39.13C434.63 34.66 428.63 31.93 421 31.93C408.13 31.93 398.85 40.87 398.85 51.82V52C398.85 62.95 407.91 72.08 421 72.08C429.73 72.08 435.06 69.16 441.06 64.6L451.75 73.62C443.9 80.65 435.18 85.03 420.45 85.03C397.98 85.03 381.29 70.52 381.29 52.18Z" fill="white"/>
        <path d="M242.96 231.45C242.96 231.45 383.17 12.78 702.07 91.46C702.07 91.46 421.6 41.34 242.96 231.45Z" fill="white"/>
</svg>
</template>

<script>
export default {
    name: 'Quebecor',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
