<template>
    <svg width="260" height="67" viewBox="0 0 260 67" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_401_2)">
        <path d="M0 44.0972V43.9588C0 31.8321 8.62231 21.8611 20.9657 21.8611C35.1275 21.8611 41.6123 32.8618 41.6123 44.886C41.6123 45.8374 41.534 46.9453 41.4557 48.0471H11.952C13.1562 53.5083 16.9375 56.3563 22.3144 56.3563C26.3486 56.3563 29.2809 55.0919 32.6046 52.0091L39.4748 58.0965C35.5188 63.0037 29.8409 66.0083 22.1519 66.0083C9.4171 66.0083 0 57.0668 0 44.0972H0ZM29.9071 40.5327C29.1966 35.1558 26.0295 31.501 20.9657 31.501C15.9019 31.501 12.7408 35.0655 11.7894 40.5327H29.9071Z" fill="#333333"/>
        <path d="M46.4292 7.30786H58.4715V65.0328H46.4292V7.30786Z" fill="#333333"/>
        <path d="M65.0947 22.656H77.137V28.6772C79.9068 25.1187 83.5436 21.8733 89.637 21.8733C95.1704 21.8733 99.3672 24.3299 101.583 28.5989C105.298 24.2517 109.73 21.8733 115.504 21.8733C124.445 21.8733 129.822 27.2562 129.822 37.4621V65.033H117.78V41.406C117.78 35.7099 115.251 32.7836 110.741 32.7836C106.231 32.7836 103.462 35.7099 103.462 41.406V65.033H91.4193V41.406C91.4193 35.7099 88.8904 32.7836 84.3745 32.7836C79.8586 32.7836 77.1009 35.7099 77.1009 41.406V65.033H65.0586L65.0947 22.656Z" fill="#333333"/>
        <path d="M135.813 7.30786H148.458V17.9894H135.813V7.30786ZM136.127 22.6558H148.169V65.0328H136.127V22.6558Z" fill="#333333"/>
        <path d="M154.798 22.6559H166.84V31.1999C169.291 25.3473 173.247 21.566 180.37 21.8611V34.4453H179.768C171.772 34.4453 166.871 39.2623 166.871 49.3959V65.0509H154.828L154.798 22.6559Z" fill="#333333"/>
        <path d="M181.135 44.0973V43.9588C181.135 31.8563 189.757 21.8853 202.1 21.8853C216.262 21.8853 222.747 32.8859 222.747 44.9102C222.747 45.8615 222.669 46.9694 222.59 48.0713H193.087C194.291 53.5325 198.072 56.3805 203.455 56.3805C207.489 56.3805 210.41 55.1161 213.733 52.0332L220.616 58.0965C216.66 63.0038 210.982 66.0084 203.287 66.0084C190.546 66.0084 181.135 57.0669 181.135 44.0973V44.0973ZM211.042 40.5328C210.325 35.1559 207.164 31.5011 202.1 31.5011C197.037 31.5011 193.87 35.0656 192.924 40.5328H211.042Z" fill="#333333"/>
        <path d="M254.093 15.2078C254.095 16.8391 253.667 18.442 252.852 19.8554C252.038 21.2687 250.865 22.4428 249.453 23.2593C248.041 24.0759 246.438 24.5062 244.807 24.5071C243.176 24.5079 241.573 24.0792 240.16 23.2641L237.21 28.3821C239.521 29.715 242.142 30.416 244.81 30.4147C247.478 30.4133 250.098 29.7097 252.408 28.3745C254.718 27.0393 256.636 25.1196 257.968 22.8083C259.301 20.497 260.001 17.8757 260 15.2078H254.093Z" fill="#FFCC33"/>
        <path d="M252.371 2.03942C250.058 0.703393 247.435 0 244.764 0C242.093 0 239.469 0.703393 237.156 2.03942L240.118 7.15741C241.532 6.33004 243.139 5.89124 244.776 5.88551C246.414 5.87979 248.024 6.30735 249.443 7.12482C250.862 7.94229 252.04 9.12057 252.857 10.5401C253.673 11.9597 254.1 13.57 254.093 15.2077H260C259.999 12.5356 259.294 9.91102 257.954 7.59892C256.615 5.28682 254.689 3.3691 252.371 2.03942V2.03942Z" fill="#246A9A"/>
        <path d="M240.13 23.2641C239.072 22.6541 238.145 21.8417 237.402 20.8734C236.658 19.9051 236.112 18.7998 235.796 17.6206C235.479 16.4414 235.398 15.2114 235.557 14.0009C235.716 12.7904 236.112 11.623 236.722 10.5654C237.533 9.15241 238.705 7.9804 240.118 7.1695L237.156 2.05151C234.846 3.38647 232.929 5.30545 231.596 7.61578C230.263 9.92611 229.561 12.5465 229.561 15.2138C229.561 17.8811 230.263 20.5014 231.596 22.8118C232.929 25.1221 234.846 27.0411 237.156 28.376L240.106 23.2581L240.13 23.2641Z" fill="#00EAE4"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'Elmire',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
