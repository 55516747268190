<template>
    <svg class="too-big" width="1007" viewBox="0 0 1007 353" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M267.1 8.97H53.7301C22.5901 8.97 0.160156 31.41 0.160156 61.17C0.160156 90.93 22.6001 113.37 53.7301 113.37H106.84V274.08C106.84 308.88 130.65 329.48 160.41 329.48C190.17 329.48 213.98 308.88 213.98 274.08V113.37H267.09C298.23 113.37 320.66 90.93 320.66 61.17C320.67 31.41 298.23 8.97 267.1 8.97Z" fill="#027AF1"/>
        <path d="M470.77 45.6C457.95 20.42 441.01 8.97 419.49 8.97C388.81 8.97 365.92 32.32 365.92 61.62C365.92 72.15 368.21 81.77 374.16 93.21L473.98 292.84C486.8 318.02 503.74 329.47 525.26 329.47C555.94 329.47 578.83 306.12 578.83 276.81C578.83 266.28 576.54 256.66 570.59 245.22L470.77 45.6Z" fill="#027AF1"/>
        <path d="M641.141 117.236C670.847 112.415 691.02 84.4256 686.199 54.7198C681.378 25.014 653.388 4.84093 623.683 9.66199C593.977 14.483 573.804 42.4726 578.625 72.1784C583.446 101.884 611.435 122.057 641.141 117.236Z" fill="#027AF1"/>
        <path d="M778.063 313.535C799.343 292.256 799.343 257.755 778.063 236.476C756.784 215.196 722.283 215.196 701.004 236.476C679.724 257.755 679.724 292.256 701.004 313.535C722.283 334.815 756.784 334.815 778.063 313.535Z" fill="#027AF1"/>
        <path d="M997.78 245.24L897.96 45.61C885.14 20.43 868.2 8.98001 846.68 8.98001C816 8.98001 793.11 32.33 793.11 61.64C793.11 72.17 795.4 81.79 801.35 93.23L901.17 292.86C913.99 318.04 930.93 329.49 952.45 329.49C983.13 329.49 1006.02 306.14 1006.02 276.84C1006.03 266.3 1003.74 256.68 997.78 245.24Z" fill="#027AF1"/>
    </svg>
</template>

<script>
export default {
    name: 'Tva',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
